.container {
  display: flex;
  flex-direction: column;
  gap: 16px;

  .message {
    display: flex;
    align-items: center;
    gap: 12px;
    font-size: 14px;
    position: relative;

    &.user {
      justify-content: flex-end;

      .msgBody {
        box-sizing: content-box;
        position: relative;
        background-color: var(--m-Blackcurrant-50);
        border-radius: 4px;
      }
    }

    &.copilot {
      .msgBody {
        padding-left: 0;
        display: flex;
        align-items: center;
        gap: 8px;
      }
    }

    .msgBody {
      position: relative;
      padding: 8px 12px;
    }

    .text {
      display: flex;
      flex-direction: column;

      &.textCopilot {
        * {
          margin: revert;
          white-space: pre-wrap;
        }
      }
    }

    .copyMessage {
      position: absolute;
      bottom: -8px;
    }

    .responseType {
      position: absolute;
      bottom: 0; 
      right: 0;
      border: 1px solid var(--m-Neutral-40);
      border-radius: 8px;
      font-size: 10px;
      padding: 0 4px;
    }

    .time {
      font-size: 12px;
      color: var(--m-Neutral);
      text-align: end;
    }

  }

  .copilotIcon {
    display: flex;
    flex: none;
    align-self: flex-start;
    margin-top: 16px;
    align-items: center;
    justify-content: center;
    border: 1px solid var(--borderColor);
    border-radius: 50%;
    width: 32px;
    height: 32px;
  }
}
