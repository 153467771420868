$btnHeight: 52px;

.container {
    position: relative;

    .toggleBtn {
        cursor: pointer;
        background: linear-gradient(180deg, #F56F4A 0%, #F1431E 100%);
        box-shadow: 1px 2px 12px 0px #00000080;
        width: $btnHeight;
        height: $btnHeight;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .dialogWrapper {
        position: absolute;
        z-index: 3;
        bottom: calc(#{$btnHeight} + 24px);
        right: 0;
        box-shadow: 0px 8px 24px 0px #00000040;
    }
}
