.container {
  background: var(--m-Neutral-80) !important;
  background-color: var(--m-Neutral-80) !important;
  border-color: var(--m-Neutral-20);

  &:focus {
    border-color: var(--m-Neutral) !important;
  }

  &:focus-visible {
    border-color: var(--m-Neutral-20) !important;
    box-shadow: none !important;
  }

  &.textarea {
    padding: 8px 12px;
    &::placeholder {
      font-weight: 400;
      font-style: italic;
      font-size: 14px;
      color: var(--m-Neutral);
    }
  }
}
