.container {
  display: flex;
  gap: 4px;

  .dot {
    animation: 1s blink infinite;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background: var(--m-Neutral);
  }


  :nth-child(2) {
    animation-delay: 200ms;
  }

  :nth-child(3) {
    animation-delay: 400ms;
  }
}

@keyframes blink {
  0% { opacity: 0; }
  50% { opacity: 1; }
  100% { opacity: 0; }
}

