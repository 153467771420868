.container {
    background: var(--menuColor);
    box-shadow: 0px 8px 24px 0px #00000040;
    width: 520px;
    height: 488px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    overflow: auto;

    .header {
        flex-grow: 0;
        background: var(--m-Blackcurrant-50);
        height: 56px;
        display: flex;
        align-items: center;
        padding: 20px;

        .headerTitle {
            font-weight: 600;
            font-size: 16px;
        }
    }

    .body {
        flex-grow: 1;
        border-top: 1px solid var(--borderColor);
        border-bottom: 1px solid var(--borderColor);
        display: flex;
        flex-direction: column;
        padding: 20px;
        gap: 16px;
    }

    .footer {
        flex-grow: 0;
        height: 52px;

        .cancelBtn {
            width: 100%;
            height: inherit;
            border-top-left-radius: 0;
            border-top-right-radius: 0;
            background: var(--m-Neutral-80);

            &:hover {
                background: var(--m-Neutral-70);
            }

        }
    }
}
