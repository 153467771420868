.container {
  background: var(--menuColor);
  box-shadow: 0px 8px 24px 0px #00000040;
  width: 520px;
  min-height: 488px;
  height: auto;
  max-height: calc(100vh - 260px);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  overflow: auto;

  .header {
    flex-grow: 0;
    background: var(--m-Blackcurrant-50);
    border-bottom: 1px solid var(--borderColor);
    height: 56px;
    display: flex;
    align-items: center;
    padding: 20px;

    .headerTitle {
      font-weight: 600;
      font-size: 16px;
    }
  }

  .body {
    flex-grow: 1;
    border-top: 1px solid var(--borderColor);
    border-bottom: 1px solid var(--borderColor);
    display: flex;
    flex-direction: column;
    padding: 20px;
    gap: 16px;
    overflow: scroll;
  }

  .footer {
    background: var(--m-Blackcurrant-50);
    flex-grow: 0;
    padding: 16px 20px;
    display: flex;
    flex-direction: column;
    gap: 12px;

    .input {

    }

    .sendBtn {
      border-radius: 50%;
      padding: 0;
    }
  }
}
